@use '@angular/material' as mat;
@import "@angular/material/theming";
@include mat-core();

$black-87-opacity: rgba(0, 0, 0, 0.87);
$mat-primary: (
  50: #e6eff3,
  100: #bfd6e1,
  200: #95bbcd,
  300: #6b9fb9,
  400: #4b8baa,
  500: #1E1EF0,
  600: #266e93,
  700: #206389,
  800: #1a597f,
  900: #10466d,
  A100: #a2d4ff,
  A200: #6fbdff,
  A400: #3ca5ff,
  A700: #239aff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);
$mat-accent: (
  50: #fce4ec,
  100: #f8bcd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: #081351,
  600: #e61a5b,
  700: #e31651,
  800: #df1247,
  900: #d90a35,
  A100: #ffffff,
  A200: #ffd0d8,
  A400: #ff9dae,
  A700: #ff8498,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-error: (
  500: #f44336,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$mat-warn: (
  50: #fce4ec,
  100: #f8bcd0,
  200: #f48fb1,
  300: #f06292,
  400: #ec407a,
  500: var(--secondary-color),
  600: #e61a5b,
  700: #e31651,
  800: #df1247,
  900: #d90a35,
  A100: #ffffff,
  A200: #ffd0d8,
  A400: #ff9dae,
  A700: #ff8498,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000
  )
);

$seq-primary: mat-palette($mat-primary);
$seq-accent: mat-palette($mat-accent);
// $seq-warn: mat.define-palette($mat-warn);
// $seq-error: mat.define-palette($mat-error);
$seq-theme: mat-light-theme($seq-primary, $seq-accent);
$background: map-get($seq-theme, background);
$foreground: map-get($seq-theme, foreground);
$myWhite: rgba(255, 255, 255, 0.9);
$berwynRed: #e52b39;
$berwynGreen: #4ab16c;

.seq-theme {
  @include angular-material-theme($seq-theme);

  .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
  .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
    color: #f44336;
    font-weight: 400;
  }
  .mat-error {
    color: #f44336;
    font-weight: 400;
  }
  .mat-form-field.mat-form-field-invalid .mat-form-field-label{
    color: #f44336;
  }
  .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick{
    color: #f44336;
  }
  .mat-form-field-invalid .mat-input-element {
    caret-color: #f44336;
  }

  .white {
    color: $myWhite;
    font-weight: 400;
    .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
    .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent,
    .mat-form-field-underline,
    .mat-form-field.mat-focused .mat-form-field-ripple {
      background-color: $myWhite;
      font-weight: 400;
    }
    .mat-stroked-button,
    .mat-stroked-button.mat-primary,
    .mat-stroked-button:not([class*="mat-elevation-z"]) {
      margin: 0 14px 14px 0;
      color: $myWhite;
      border-color: $myWhite;
    }

    .mat-stroked-button[disabled][disabled] {
      margin: 0px 14px 14px 0;
      color: rgba(255, 255, 255, 0.6);
      border-color: rgba(255, 255, 255, 0.6);
    }
    .mat-form-field {
      display: flex;
      flex-direction: row;
      margin: 16px 0;
      .hidden {
        display: none;
      }
    }
    p,
    h1,
    h2,
    h3,
    a {
      color: $myWhite;
      font-weight: 400;
      text-decoration: none;
    }
    h3 {
      font-size: 18px;
    }
    .mat-input-element {
      width: 100%;
      color: $myWhite;
      caret-color: #000000;
      font-weight: 400;
    }
    .mat-form-field-label .mat-form-field-required-marker {
      color: $myWhite;
      font-weight: 400;
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
    .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
      color: #f44336!important;
      font-weight: 400;
    }
    .mat-error {
      color: #f44336 !important;
      font-weight: 400;
    }
    .mat-toolbar {
      background: none;
    }
    .mat-form-field.mat-form-field-invalid .mat-form-field-label {
      color: #f44336!important;
    }
  }
  .mat-snack-bar-container.white {
    color: #f44336;
    .mat-button {
      color: #f44336
    }
  }

  .mat-nav-list .mat-list-item:focus {
    background: none;
  }

  .mat-card[class*="mat-elevation-z"],
  .mat-dialog-container,
  .mat-select-panel,
  .mat-card,
  .mat-card:not([class*="mat-elevation-z"]),
  .mat-menu-panel {
    font-family: 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    background: #fff;
    border-radius: 2px;
  }

  .mat-cell,
  .mat-footer-cell {
    font-size: 1em;
    padding-right: 12px;
    margin: 12px 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
  }

  .mat-header-cell {
    padding-right: 12px;
    font-size: 13px;
    font-weight: 600;
  }

  .mat-header-row {
    min-height: 42px;
  }

  .mat-mini-fab[disabled][disabled] {
    background-color: #ffffff;
    .mat-icon.mat-primary {
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .mat-form-field {
    margin: 8px 0;
  }

  .mat-form-field-infix {
    border-top: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-label {
    margin-top: 0;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.42);
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    border-left: 2px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-end,
  .mat-form-field-appearance-outline._mat-animation-noopable .mat-form-field-outline-end {
    border-right: 2px solid;
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mat-form-field-appearance-outline .mat-form-field-outline-gap {
    border-top: 2px solid;
    border-bottom: 2px solid;
  }
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(10%);
  }

  .mat-form-field-appearance-outline .mat-form-field-prefix,
  .mat-form-field-appearance-outline .mat-form-field-suffix {
    top: 0.5em;
    color: rgba(0, 0, 0, 0.56);
  }

  :not(.mat-form-field-appearance-legacy).mat-form-field .mat-form-field-prefix .mat-icon-button,
  :not(.mat-form-field-appearance-legacy).mat-form-field .mat-form-field-suffix .mat-icon-button {
    display: inline-block;
    height: 1.5em;
    width: 1.5em;
  }

  .mat-select-panel mat-option.mat-option {
    height: unset;
  }

  .mat-option-text.mat-option-text {
    line-height: 26px;
    margin: 12px 0;
    white-space: normal;
  }

  .no-spacing .mat-form-field-wrapper {
    margin: 0;
    padding-bottom: 0;
  }

  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
    color: rgba(0, 0, 0, 0.12);
  }

  .mat-input-element:disabled,
  .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after,
  .mat-select-disabled .mat-select-value {
    color: rgba(0, 0, 0, 0.38);
  }


  .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
    color: rgba(0, 0, 0, 0.56);
  }

  .set-password-input-width {
    .mat-form-field-infix {
      width: 200px;
    }
  }
  //changes for COMPLY-5
  .material-13{
    color: rgba(0,0,0,.87)!important;
    h2,
    h3,
    .mat-input-element{
      color: rgba(0,0,0,.87);
    }
    h3{
      margin-bottom: 30px;
    }
    .white{
      color: rgba(0,0,0,.87)!important;
      .mat-form-field-underline{
        background-color: rgba(0,0,0,.87)!important;
      }
    }
    .mat-form-field-underline{
      background-color: rgba(0,0,0,.87)!important;
    }
    .mat-form-field-required-marker{
      color: rgba(0,0,0,.87)!important;
    }
    .mat-form-field.mat-focused{
      .mat-form-field-ripple{
        background-color: rgba(0,0,0,.87)!important;
      }
    }
    .mat-form-field-infix{
      width: 333px!important;
    }
    .mat-flat-button{
      background-color: #161A33;
      color: #fff;
    }
  }
}
