
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$bw-workshop-mis-ui-primary: mat.define-palette(mat.$indigo-palette);
$bw-workshop-mis-ui-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$bw-workshop-mis-ui-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bw-workshop-mis-ui-theme: mat.define-light-theme((
  color: (
    primary: $bw-workshop-mis-ui-primary,
    accent: $bw-workshop-mis-ui-accent,
    warn: $bw-workshop-mis-ui-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bw-workshop-mis-ui-theme);

@import 'main';
@import 'theme';
@import 'shared';
@import 'responsive';
@import 'snackbar';
@import 'gds';
@import '~angular-calendar/css/angular-calendar.css';
html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto', sans-serif; background: #f6f4ee;}

.label-hr {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 16px 0;
  color: #0b0c0c;

  span {
    font-size: 1em;
    font-weight: bold !important;
    padding-right: 8px;
    color: #0b0c0c;
  }

  &:after {
    content: "";
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
    margin-left: 0.25em;
  }
  &:before {
    content: "";
    width: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.24);
  }
}